import React from 'react';

import PageView from './PageView';
import MessageView from './MessageView';
import LoginView from './Login/LoginView';
import LoginLostPassword from './Login/LoginLostPassword';
import UserAreaView from './UserArea/UserAreaView';
import ActivationPage from './Activation/ActivationPage';
import AdminPersons from './Person/AdminPersons';
import AdminPerson from './Person/AdminPerson';
import AdminPages from './Page/AdminPages';
import AdminPage from './Page/AdminPage';
import AdminProducts from './Product/AdminProducts';
import AdminProduct from './Product/AdminProduct';
import AdminOrders from './Activation/AdminOrders';
import SupportPage from './SupportPage';
import ResendSerial from './ResendSerial';
import TransferLicense from './UserArea/TransferLicenseView';
import FastSpringUrlCheckout from './FastSpring/FastSpringUrlCheckout';
import { BrowserRouter as Router, Route, NavLink, Routes } from "react-router-dom";

import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Config } from '../config';

import { AuthenticationService } from '../authenticationService';

import '../custom.scss';

class Navigation extends React.Component {
    constructor(props) 
    {
      super(props);
      
      this.state = {
        menuItems: [],
      };

      this.handleUserChange = this.handleUserChange.bind(this);
      //this.menuItems = [{"date":"0001-01-01T00:00:00","htmlText":"","id":103,"isMainMenu":true,"mainMenuPriority":5,"name":"Products","url":"/products"}];
    }

    handleUserChange()
    {
      this.forceUpdate();
    }

    componentDidMount()
    {
      this.fetchMenuItems();
      AuthenticationService.currentUser.subscribe(value => this.handleUserChange());
    }

    fetchMenuItems() {
        fetch(Config.apiUrl + "/page/menuitems")
            .then(response => response.json())
            .then(data => this.setState({menuItems: data}));
    }

    render()
    {
      const { menuItems } = this.state;
        return (
          <Router>
          <Navbar className="navbar navbar-dark overlay" expand="lg" sticky="top">

          <div className="container">
          {/* "Link" in brand component since just redirect is needed */}
          <Navbar.Brand as={NavLink} to="/">
            <img className="no_shadow" src="../../logo.svg" alt="TAL" width="180px"/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              {menuItems.map(n =>
                          <Nav.Link key={n.name} as={NavLink} to={n.url}>{n.name.toUpperCase()}</Nav.Link>
                      )}
            </Nav>
            <Nav className="ms-auto" >
              <Nav.Link  key="Support" as={NavLink} to="/support">SUPPORT</Nav.Link>
              {(AuthenticationService.currentUserValue) ? (
                <Nav.Link key="UserArea" as={NavLink} to="/userarea">MY ACCOUNT</Nav.Link>
              ) : <Nav.Link key="Login" as={NavLink} to="/login"><i className="material-icons">input</i> LOGIN</Nav.Link>
              }

              {(AuthenticationService.currentUserValue && AuthenticationService.currentUserValue.name === "admin") ? (
                  <NavDropdown key="admin" title="ADMIN" id="basic-nav-dropdown">

                  <NavDropdown.Item href="/admin/persons">PERSONS</NavDropdown.Item>
                  <NavDropdown.Item href="/admin/pages">PAGES</NavDropdown.Item>
                  <NavDropdown.Item href="/admin/products">PRODUCTS</NavDropdown.Item>
                  <NavDropdown.Item href="/admin/orders">ACTIVATION CODES</NavDropdown.Item>
                  <NavDropdown.Item href="/activate">ACTIVATION PAGE</NavDropdown.Item>

                  </NavDropdown>
              ) : <div></div>
              }

              {(AuthenticationService.currentUserValue) ? (
                  <Nav.Link key="Logout" as={NavLink} to="/" onClick={() => AuthenticationService.logout()}><i className="material-icons">logout</i> LOGOUT</Nav.Link>
              ) : <div></div>
              } 
            </Nav>
          </Navbar.Collapse>
          </div>
        </Navbar>
        <Routes>
          <Route path="/admin/resendserial/:productName" element={<ResendSerial/>}/>  
          <Route path="/transferlicense/:id" element={<TransferLicense/>} /> 
          <Route path="/directurlcheckout/:productName?/:couponCode" element={<FastSpringUrlCheckout/>} />
          <Route path="/login/lostpassword" element={<LoginLostPassword/>}/>  
          <Route path="/login/" element={<LoginView/>} /> 
          <Route path="/contactsupport" element={<SupportPage/>}/>
          <Route path="/messageview" element={<MessageView/>} />      
          <Route path="/userarea" element={<UserAreaView/>} /> 
          <Route path="/activate" element={<ActivationPage/>} /> 
          <Route path="/admin/orders" element={<AdminOrders/>} /> 
          <Route path="/admin/persons" element={<AdminPersons/>} /> 
          <Route path="/admin/products" element={<AdminProducts/>} /> 
          <Route path="/admin/product/:id" element={<AdminProduct/>} />
          <Route path="/admin/person/:id" element={<AdminPerson/>} />
          <Route path="/admin/page/:id" element={<AdminPage/>} />
          <Route path="/admin/pages" element={<AdminPages/>} />
          <Route path="/:pathParam1?/:pathParam2?/:pathParam3?" element={<PageView/>} /> 
        </Routes>               
      </Router>);
    }
}

export default Navigation;