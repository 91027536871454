import React, { useEffect, useMemo, useState } from "react"

import TableContainer from "../Table/TableContainer"

import AuthHeader from '../../AuthHeader';
import { HandleResponse } from '../../handleResponse';
import { Config } from '../../config';
import { Button } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

async function fetchPagesAsync() 
{
    const response = await fetch(Config.apiUrl + "/person/",
            {
                method: 'GET',
                headers: AuthHeader()
            })

     return await HandleResponse(response);
}

async function deleteAsync(id) 
{
    const response = await fetch(Config.apiUrl + '/persons/' + id,
        {
            method: 'DELETE',
            headers: AuthHeader()
        })

        return await HandleResponse(response);
} 

export default function AdminPersonTable()
{
    const [data, setData] = useState([])
    const navigate  = useNavigate();

    useEffect(() => 
    { // component did mount replacement
      const doFetch = async () => {
        const contacts = await fetchPagesAsync().catch(error => console.log("error: " + error));
        setData(contacts)
      }
      doFetch()
    }, [])

    function EditPerson(props)
    {
        navigate('/admin/person/' + props.row.values.id, { replace: true });
    }

    async function DeletePerson(props) 
    {
        await deleteAsync(props.row.values.id);
        navigate('/admin/persons/', { replace: true });
    } 

    const columns = useMemo(
        () => [
            {
                Header: "Id",
                accessor: "id",
                Cell: row => <div style={{ textAlign: "right"}}>{row.value}</div>,
            },
            {
                Header: "Email",
                accessor: "email",
                minWidth: 100,
                width: 450,
                maxWidth: 600
            },
            {
                Header: "Name",
                accessor: "name",
                minWidth: 100,
                width: 450,
                maxWidth: 600
            },
            {
                Header: " ",
                Cell: (props) => <Button variant="secondary" onClick=
                {
                    () => EditPerson(props, navigate)
                } 
                >EDIT</Button>,
                minWidth: 100,
                width: 100,
                maxWidth: 100,
                isResizing: false
            },
            {
                Header: "  ",
                Cell: (props) => <Button variant="secondary" onClick=
                {
                    () => DeletePerson(props, navigate)
                } 
                >X</Button>,
                minWidth: 50,
                width: 50,
                maxWidth: 50,
                isResizing: false
            }
        ],
        []
    )
  
    return <TableContainer columns={columns} data={data} width="100%"/>;
  }
