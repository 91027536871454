import React from 'react';

import { useForm } from "react-hook-form";
import { Config } from '../../config';

import { HandleResponse } from '../../handleResponse';
import AuthHeader from '../../AuthHeader';

import { useNavigate } from 'react-router-dom';

import { useParams } from 'react-router-dom';

import {
    Form,
    Button,
  } from "react-bootstrap";

  function fetchValidateTransfer(params) {
    return fetch(Config.apiUrl + '/userinfo/verifyorderfortransfer/' + params.id,
            {
                method: 'GET',
                headers: AuthHeader()
            })
        .then(secretKey => HandleResponse(secretKey));
        // .then(secretKey => this.setState({ secretKey: secretKey }));
}  

export default function TransferLicenseForm() 
{
    const { register, handleSubmit, watch, getValues, setError, formState: { errors } } = useForm();
    const params  = useParams();
    const navigate  = useNavigate();

    const onSubmit = (data) => 
    {
        fetchValidateTransfer(params)
        .then(
            (secretKey) => 
            {
                // 2Checkout
                // window.location.href=encodeURI('https://secure.2checkout.com/order/checkout.php?PRODS=33597137&QTY=1&CART=1&CARD=2&ORDERSTYLE=nLWs4pW5rnM=&SHORT_FORM=1&REF=' + params.id + ";" + data.email + ";" + data.firstName + ";" + data.lastName + ";" + secretKey);
                
                window.fastspring.builder.reset();
                window.fastspring.builder.tag({ 'REFNOEXT': params.id + ";" + data.email + ";" + data.firstName + ";" + data.lastName + ";" + secretKey });
                window.fastspring.builder.push(
                  {
                  products: [
                    {
                      path: "licensetransfer",
                      quantity: 1
                    }
                  ],
                  checkout: true
                });  
            
                // navigate("/messageview", { state: { message: "License transferred to " + data.email } });
                navigate("/userarea");
            },
            error => 
            {
              setError("lastName", { message: "License transfer failed. (" + error + ")" });
            }
        );
    }
  
    console.log(watch("example")); // watch input value by passing the name of it
  
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Control placeholder="Email"
          id="email"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /\S+@\S+\.\S+/, // email or admin
              message: "Please enter a valid email address"
            }
          })}
          type="text"
        />
        {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}<br/>

        <Form.Control placeholder="Confirm Email"
          id="emailConfirm"
          {...register("emailConfirm", {
            validate: value => { if (value === getValues("email")) return true; else return "Email does not match" },
            required: "Email is required",
            pattern: {
              value: /\S+@\S+\.\S+/, // email or admin
              message: "Please enter a valid email address"
            }
          })}
          type="text"
        />
        {errors.emailConfirm && <Form.Text className="text-danger">{errors.emailConfirm.message}</Form.Text>}<br/>

        <Form.Control placeholder="First Name"
          id="firstName"
          {...register("firstName", {
            required: "Frist name is required.",
            minLength: {
              value: 3,
              message: "Frist name is required."
            }
          })}
          type="text"
        />
        {errors.firstName && <Form.Text className="text-danger">{errors.firstName.message}</Form.Text>}<br/>

        <Form.Control placeholder="Last Name"
          id="lastName"
          {...register("lastName", {
            required: "Last name is required.",
            minLength: {
              value: 3,
              message: "Last name is required."
            }
          })}
          type="text"
        />
        {errors.lastName && <Form.Text className="text-danger">{errors.lastName.message}</Form.Text>}<br/>

        <Button variant="primary"  type="submit">TRANSFER LICENSE</Button>
        <div>&nbsp;</div>
      </Form>
    );
  }