import React from 'react';

import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';

class LoginView extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({ username: '', password: '' });
    }

    render() {
        return <div className="container">
            <div class="newstitle">
                <div class="well text-center">
                    <h2>&nbsp;</h2>
                </div>
            </div>
            <div className="well">
                <div className="row content no-gutters">
                    <div className="col-sm-3">
                    </div>
                    <div className="col-sm-3">
                        <div className="well_content">
                            <h3>Login</h3>
                            <LoginForm />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="well_content">
                            <h3>Register</h3>
                            <RegisterForm />
                        </div>
                    </div>
                </div>
                <div className="col-sm-3">
                </div>
                <div>&nbsp;</div>
            </div>
        </div>
    }
}

export default (props) => (<LoginView {...props} params={useParams()} navigate={useNavigate()} location={useLocation()} />);