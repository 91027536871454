import React from 'react';

import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

class MessageView extends React.Component {
    render() {
        return <div className="container">
            <div class="newstitle">
                <div class="well">
                    <h2>Info</h2>
                </div>
            </div>
            <div className="well">
                <h3>{this.props.location.state.message}</h3>
            </div>
        </div>
    }
}

export default (props) => (<MessageView {...props} params={useParams()} navigate={useNavigate()} location={useLocation()} />);