import React from 'react';

import { useForm } from "react-hook-form";
import { Config } from '../../config';

import { HandleResponse } from '../../handleResponse';
import AuthHeader from '../../AuthHeader';

import { useNavigate } from 'react-router-dom';

import {
    Form,
    Button,
  } from "react-bootstrap";

  function changePassword(newPassword)
  {
      let jsonString = JSON.stringify({ NewPassword: newPassword });
      return fetch(Config.apiUrl + '/passwordchange',
          {
              method: 'POST',
              headers: AuthHeader(),
              body: jsonString
          })
          .then(HandleResponse);
  }

export default function ChangePasswordForm() 
{
    const { register, handleSubmit, watch, getValues, setError, formState: { errors } } = useForm();
    const navigate  = useNavigate();

    const onSubmit = (data) => 
    {
      changePassword(data.password)
            .then(
                user => {
                    navigate("/login" , { replace: true });
                },
                error => {
                    setError("passwordrepeat", { message: "Error while changing password." });
                }
            );
    }
  
    console.log(watch("example")); // watch input value by passing the name of it
  
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Label htmlFor="password">Password:</Form.Label><br/>
        <Form.Control className="w-25"
          id="password"
          {...register("password", {
            required: "required",
            minLength: {
              value: 4,
              message: "Please enter a valid password"
            }
          })}
          type="password"
        />
        {errors.password && <Form.Text className="text-danger">{errors.password.message}</Form.Text>}<br/>
        <Form.Label htmlFor="passwordrepeat">Repeat Password:</Form.Label><br/>
        <Form.Control className="w-25"
          id="passwordrepeat"
          {...register("passwordrepeat", {
            validate: value => { if (value === getValues("password")) return true; else return "Password does not match" },
            required: "required",
            minLength: {
              value: 4,
              message: "Please enter a valid password"
            }
          })}
          type="password"
        />
        {errors.passwordrepeat && <Form.Text className="text-danger">{errors.passwordrepeat.message}</Form.Text>}<br/>
        <Button variant="primary"  type="submit">CHANGE PASSWORD</Button>
        <div>&nbsp;</div>
      </Form>
    );
  }