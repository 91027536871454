import React, { useEffect, useState } from 'react';
import './App.css';
import Navigation from './components/Navigation'

import ShopScriptLoader from './shopScripLoader'

export default function App() 
{
  const [paddle, setPaddle] = useState(null);

  useEffect(() => 
  {
    /*
    <script src="https://cdn.paddle.com/paddle/v2/paddle.js"></script>
    <script type="text/javascript">
      Paddle.Environment.set("sandbox");
      Paddle.Setup({ 
        seller: 179528 // replace with your Paddle seller ID 
      });
    </script>
      */

    /*
    Paddle: 
  const addSBL = () => {
    const scriptId = "paddle";
    const existingScript = document.getElementById(scriptId);
    if (!existingScript) 
    {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://cdn.paddle.com/paddle/v2/paddle.js";
      document.body.appendChild(script);
      script.addEventListener('load', () =>
       {
        const paddle = global.Paddle; // || null;
        paddle.Environment.set("sandbox");
        // paddle.Setup({ 15159, eventCallback });
        paddle.Setup({ seller: 15159 });
        setPaddle(paddle);
      });
    }
  };
  addSBL(); 
  */

    // Following code is only for fastspring checkout
    // Add SBL script programatically
    const addSBL = () => {
      ShopScriptLoader(true, true);
    };
    addSBL(); 
  }, []); 


  return (
    <div>
      <Navigation/>
    </div>
  );
}

