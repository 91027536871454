import React, { useEffect, useMemo, useState } from "react"

import AuthHeader from '../../AuthHeader';
import { HandleResponse } from '../../handleResponse';
import { Config } from '../../config';

import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import {
    Form,
    Button,
    Container,
    Col,
    Row
} from "react-bootstrap";

import { useForm } from "react-hook-form";

function save(product) {
    let jsonString = JSON.stringify(product);
    fetch(Config.apiUrl + '/product/',
        {
            method: 'POST',
            headers: AuthHeader(),
            body: jsonString
        })
        .then(HandleResponse);
}

function saveSerial(serial) {
    let jsonString = JSON.stringify(serial);
    fetch(Config.apiUrl + '/productserial/',
        {
            method: 'POST',
            headers: AuthHeader(),
            body: jsonString
        })
        .then(HandleResponse);
}

async function fetchProduct(id) {
    let response = await fetch(Config.apiUrl + `/product/${id}`,
        {
            method: 'GET',
            headers: AuthHeader()
        });
    return await HandleResponse(response);
}

async function fetchProductSerial(id) {
    let response = await fetch(Config.apiUrl + `/productserial/${id}`,
        {
            method: 'GET',
            headers: AuthHeader()
        })
    return await HandleResponse(response);
}

export default function AdminProduct() {
    const { register, handleSubmit, setValue, setError, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [productData, setProductData] = useState([]);
    const [productSerialsData, setProductSerialsData] = useState([]);
    const params = useParams();

    useEffect(() => {
        const doFetch = async () => {
            const product = await fetchProduct(String(params.id)).catch(error => console.log("error: " + error));
            setProductData(product);

            const serials = await fetchProductSerial(String(params.id)).catch(error => console.log("error: " + error));
            setProductSerialsData(serials);

            setValue("name", product.name);
            setValue("description", product.description);
            setValue("price", product.price);
            setValue("privateKey", product.privateKey);
            setValue("publicKey", product.publicKey);
            setValue("serials", serials.serials);
        }
        doFetch()
    }, [])

    return (
        <Container>
            <div class="newstitle">
                <div class="well">
                    <h5>Product</h5>
                </div>
            </div>
            <div className="well">
                <Form onSubmit={handleSubmit(data => { save(productData); saveSerial(productSerialsData); })}>
                    <h5>Edit page (id: {productData.id})</h5>
                    <Row>
                        <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <Form.Label htmlFor="name">Name</Form.Label><br />
                            <Form.Control id="name" placeholder="My Page Name" {...register("name", { required: "Name is required" })} type="text" onChange={(e) => setProductData({ ...productData, name: e.target.value })} />
                            {errors.name && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}<br />
                        </Col>

                        <Col style={{ paddingLeft: 10, paddingRight: 0 }}>
                            <Form.Label htmlFor="description">Description:</Form.Label><br />
                            <Form.Control id="description" placeholder="/test/test" {...register("description", { required: "Description is required" })} type="text" onChange={(e) => setProductData({ ...productData, description: e.target.value })} />
                            {errors.description && <Form.Text className="text-danger">{errors.description.message}</Form.Text>}<br />
                        </Col>

                    </Row>

                    <Form.Label htmlFor="price">Price:</Form.Label><br />
                    <Form.Control id="price" placeholder="0" {...register("price", { required: "Price is required" })} type="text" onChange={(e) => setProductData({ ...productData, price: e.target.value })} />
                    {errors.price && <Form.Text className="text-danger">{errors.price.message}</Form.Text>}<br />

                    <Form.Label htmlFor="privateKey">PrivateKey:</Form.Label><br />
                    <Form.Control id="privateKey" {...register("privateKey")} type="text" onChange={(e) => setProductData({ ...productData, privateKey: e.target.value })} />

                    <Form.Label htmlFor="publicKey">PublicKey:</Form.Label><br />
                    <Form.Control id="publicKey" {...register("publicKey")} type="text" onChange={(e) => setProductData({ ...productData, publicKey: e.target.value })} />

                    <Form.Label htmlFor="serials">Serials:</Form.Label><br />
                    <Form.Control as="textarea" rows={10} type="textarea" id="serials" {...register("serials")} onChange={(e) => setProductSerialsData({ ...productSerialsData, serials: e.target.value })} />

                    <div>&nbsp;</div>

                    <br />
                    <Button variant="primary" type="submit">SAVE PRODUCT</Button>
                    <div>&nbsp;</div>
                </Form>
            </div>
        </Container>);
}