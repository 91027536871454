import React from 'react';
import { Config } from '../config';
import { Helmet } from "react-helmet";

import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

class PageView extends React.Component
{
    constructor(props)
    {
        super(props);
        this.notFoundState = false;

        this.state = {
            page: {"date":"0001-01-01T00:00:00","htmlText":"<div className='loader' key='loader'/>","id":102,"isMainMenu":true,"mainMenuPriority":0,"name":"About","url":"/notExists"},
            visible: false
        };

        this.handleOnClick = this.handleOnClick.bind(this);
    }

    fetchPage(urlIn) {
        urlIn = urlIn.replace(/\//g, "*");
        fetch(Config.apiUrl + "/page/byUrl/" + urlIn)
            .then(response => response.json())
            .then(data => 
                { 
                    this.setState({ page: data, visible: true });
                    // this.notFoundState = false; // keep error state, this is only for cashing
                }) 
            .catch(error => 
                {
                    this.notFoundState = true; // notfound tells prerender.io that the url was not valid
                    this.props.navigate("/", { replace: true });
                });
    }

    componentWillUnmount() 
    {
        document.removeEventListener('click', this.handleOnClick, false);
    }

    componentDidCatch(error, info) {
    }   

    componentDidMount()
    {
        this.componentDidUpdate();
        this.loadScripts();
    }

    componentDidUpdate()
    { 
        const { page } = this.state;

        var separator = '/';
        var currentPathName = separator.concat(this.props.params.pathParam1 ?? '')
                                        .concat(this.props.params.pathParam2 === undefined ? '' : separator.concat(this.props.params.pathParam2 ?? ''))
                                        .concat(this.props.params.pathParam3 === undefined ? '' : separator.concat(this.props.params.pathParam3 ?? ''));
        if (currentPathName.toLowerCase() === page.url.toLowerCase()) 
        {
            return;
        }

        page.url = currentPathName;
        this.fetchPage(currentPathName);
        this.setState({ page: this.state.page, visible: false, notfound: this.state.notfound });
    }

    fastSpringCallBack = (data) => {
        /* Iterate over payload to find whatever information is relevant
            For this example we'll want to display localized price of
            the product 'demo-product-4'

        if (data && data.groups) {
            //setCountry(data.country);
            data.groups.forEach((group) => {
            if (group.items && Array.isArray(group.items)) {
                group.items.forEach((item) => {
                if (item.path === "demo-product-4") {
                    // Update local state to add localized product information
                    //setItem(item);
                }
                });
            }
            });
        }
        */
    }

    loadScripts()
    {
    }

    render()
    {
        const { page } = this.state;

        return (
                <div>
                <Helmet>
                    <meta property="og:title" content={ this.getFirstTag(page.htmlText, "h1") } />
                    <meta property="og:description" content={ "VST3, Audio Unit, AAX, CLAP audio plug-ins for macOS, Windows and Linux" } />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={ window.location.href } /> 
                    <meta name="og:site_name" content={ page.name } />
                    <meta property="og:image" content={ this.getFirsImageUrl(page.htmlText) } />
                    <meta property="og:image:secure_url" content={ this.getFirsImageUrl(page.htmlText) } />
                    { this.notFoundState ? <meta name="prerender-status-code" content="404" />  : null }
                </Helmet>
                <div className="container">
                <div onClick={this.handleOnClick} dangerouslySetInnerHTML={{ __html: page.htmlText }}></div>
                </div>
                </div>
            );
    }

    handleOnClick(e) 
    {
        if (e.metaKey || e.ctrlKey || e.shiftKey) 
        {
            return;
        }

        if (e.button !== 0) 
        {
            return;
        }

        if (this.handleBuyNowButtonClick(e))
        {
            return;
        }

        // Get the <a> element.
        var el = e.target;
        while (el && el.nodeName !== 'A') {
            el = el.parentNode;
        }

        // Ignore clicks from non-a elements.
        if (!el) {
            return;
        }

        // Ignore the click if the element has a target.
        if (el.target && el.target !== '_self') {
            return;
        }

        // Ignore the click if it's a download link. (We use this method of
        // detecting the presence of the attribute for old IE versions.)
        if (el.attributes.download) {
            return;
        }

        let href = (String)(el.getAttribute('href'));
        // Ignore hash (used often instead of javascript:void(0) in strict CSP envs)
        if (href === '#') {
            return;
        }

        if (href.indexOf("/") !== 0) {
            return;
        }
        e.preventDefault();

        this.props.navigate(href, { replace: true });
    }

    getFirsImageUrl(str)
    {
        var searchRegex = "<img[^]*?src=\"(.*?)\"";
        var seReg = new RegExp(searchRegex, "i");
        var result = str.match(seReg);

        if (result == null)
        {
            return "";
        }

        var urlAbsolute = new URL(result[1], window.location.href).href;
        return urlAbsolute;
    }

    getFirstTag(str, tagName)
    {
        var searchRegex = "<" + tagName + ">(.*?)</" + tagName + ">";
        var seReg = new RegExp(searchRegex, "i");
        var result = str.match(seReg);

        if (result == null)
        {
            return "TAL Software GmbH";
        }

        return result[1];
    }

    handleBuyNowButtonClick(e)
    {
        let productName = e.target.getAttribute('productToCheckout');
        if (productName === undefined)
        {
            return false;
        }

        this.buyProductGlobalFastspring(productName);
        return true;
    }

    buyProductGlobalFastspring(productPath)
    {
        if (window.fastspring === undefined)
            return;

        // window.fastspring.builder.add(productPath, 1);
        // window.fastspring.builder.viewCart();

        window.fastspring.builder.push(
            {
                products: [
                {
                    path: productPath,
                    quantity: 1
                }],
                checkout: true
            });
    }

    buyProductGlobalPaddle(productPath)
    {
        if (global.Paddle === undefined)
            return;
    

        var env = global.Paddle.Environment.get();

        global.Paddle.Checkout.open({
        settings: {
            theme: "dark"
        },
        items: [
            {
            priceId: productPath,
            quantity: 1
            },
        ]
        });
    }
}

export default (props) => ( <PageView {...props} params={useParams()} navigate={useNavigate()}/> );