import React from 'react';
import { useForm } from "react-hook-form";
import { Config } from '../config';
import { HandleResponse } from '../handleResponse';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";

import {
    Form,
    Button,
} from "react-bootstrap";

export default function ResendSerial() {
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const params = useParams();

    function resendSerial(email) {
        let jsonString = JSON.stringify({ email: email, productName: params.productName });
        return fetch(Config.apiUrl + '/order/resend/',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: jsonString

            })
            .then(HandleResponse);
    }

    const onSubmit = (data) => {
        resendSerial(data.email)
            .then(
                user => {
                    navigate("/messageview", { state: { message: "Email sent to " + data.email } });
                },
                error => {
                    setError("email", { message: "Send serial failed: (" + error + ")" });
                }
            );
    }

    return (
        <div>
            <div class="newstitle">
                <div class="well">
                    <h2>Send License Key</h2>
                </div>
            </div>
            <div className="well">
                <div className="container">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Label htmlFor="email">Email:</Form.Label><br />
                        <Form.Control
                            id="email"
                            {...register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: /\S+@\S+\.\S+/, // email or admin
                                    message: "Please enter a valid email address"
                                }
                            })}
                            type="text"
                        />
                        {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}<br />

                        <Button variant="primary" type="submit">SEND</Button>
                    </Form>
                </div>
            </div>
        </div>
    );
}