import {React, useEffect} from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";

import ShopScriptLoader from "../../shopScripLoader";

import {
    Form,
    Button,
  } from "react-bootstrap";

export default function FastSpringUrlCheckout() {
    const { register, handleSubmit, setError, setValue, formState: { errors } } = useForm();
    const navigate  = useNavigate();
    const params  = useParams();

    useEffect(() => 
    { 
        const directUrl = () => 
        {
            ShopScriptLoader(false, false, async function ()
                {
                    if (window.fastspring === undefined)
                    {
                        return;
                    }
        
                    while (true) // retry until we leave page
                    {
                        window.fastspring.builder.push(
                            {
                                products: [
                                {
                                    path: params.productName.toLocaleLowerCase(),
                                    quantity: 1
                                }],
                                checkout: true,
                                coupon: params.couponCode
                            });

                        await wait(500);
                    }
                });
        }
        
        directUrl();
    }, [])

    function wait(time) {
        return new Promise(resolve => {
            setTimeout(resolve, time);
        });
    }

    return (
        <div className="container"></div>
    );
  }