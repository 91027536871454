export default function ShopScriptLoader(exectueAsync, popup, loadCallback) 
{
    const scriptId = "fsc-api";
    const existingScript = document.getElementById(scriptId);
    if (!existingScript) {
        // const storeFrontToUse = "talsoftware.test.onfastspring.com/popup-talsoftware";
        let storeFrontToUse = "";
        if (popup)
        {
            storeFrontToUse = "talsoftware.onfastspring.com/popup-talsoftware";

        }
        else
        {
            storeFrontToUse = "talsoftware.onfastspring.com";
        }
        // const storeFrontToUse = "talsoftware.onfastspring.com";
    
        const script = document.createElement("script");
    
        script.type = "text/javascript";
        script.id = scriptId;
        script.src = "https://sbl.onfastspring.com/sbl/1.0.1/fastspring-builder.min.js";
        script.async = exectueAsync;
        script.defer = exectueAsync;
        script.dataset.storefront = storeFrontToUse;
        script.dataset.continuous = true; // works only when opening cart
    
        script.addEventListener('load', loadCallback);
    
        // Make sure to add callback function to window so that the DOM is aware of it
        // window.fastSpringCallBack = fastSpringCallBack;
        // script.setAttribute("data-data-callback", "fastSpringCallBack");
        document.body.appendChild(script);
    
        // const scriptDirectCheckout = document.createElement("script");
        // scriptDirectCheckout.type = "text/javascript";
        // scriptDirectCheckout.src = "fastSpringDirectCheckout.js";
        // document.body.appendChild(scriptDirectCheckout);
    }
};

