import React from 'react';

import { useForm } from "react-hook-form";
import { Config } from '../../config';

import { HandleResponse } from '../../handleResponse';
import { useNavigate } from 'react-router-dom';

import {
    Form,
    Button,
    Row,
    Col,
    Container,
} from "react-bootstrap";

function registerNewProduct(activationKey, email, firstName, lastName) {
    let jsonString = JSON.stringify(
        { ActivationKey: activationKey, Email: email, FirstName: firstName, LastName: lastName });
    return fetch(Config.apiUrl + '/userinfo/registerproduct',
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: jsonString
        })
        .then(HandleResponse);
}

export default function TransferLicenseForm() {
    const { register, handleSubmit, watch, getValues, setError, formState: { errors } } = useForm();
    const navigate = useNavigate();

    const onSubmit = (data) => {
        registerNewProduct(data.activationKey, data.email, data.firstName, data.lastName)
            .then(
                () => {
                    navigate("/messageview", { state: { message: "Thanks for activation. We have sent the serial key to " + data.email } });
                },
                error => {
                    setError("firstName", { message: "Error: " + error });
                }
            );
    }

    return (
        <div className="container">
            <div class="newstitle">
                <div class="well">
                    <h2>Activate TAL Product</h2>
                </div>
            </div>
            <div className="well">
                <p>If you have bought your TAL product from a retailer, you have received a product activation code. Before you can use the purchased plug-ins, you need to enter the activation code and fill in the necessary contact information.
                    <br /><br />
                    After you have filled in the following form, a personal product license key will be sent to you by e-mail immediately, using the e-mail address entered below.</p>
                <h3>Activation</h3>
                <Form onSubmit={handleSubmit(onSubmit)}>

                    <Form.Label htmlFor="activationKey">Activation Key:</Form.Label>
                    <Form.Control className="w-25" placeholder="XXXXXXXX-XXXX-XXXX-XXXX"
                        id="activationKey"
                        {...register("activationKey", {
                            required: "Activation key is required",
                            pattern: {
                                value: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}$/i,
                                message: "A valid activation key must be entered"
                            }
                        })}
                        type="text"
                    />
                    {errors.activationKey && <Form.Text className="text-danger">{errors.activationKey.message}</Form.Text>}<br />

                    <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Row>
                            <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <Form.Control placeholder="Email"
                                    id="email"
                                    {...register("email", {
                                        required: "Email is required",
                                        pattern: {
                                            value: /\S+@\S+\.\S+/, // email or admin
                                            message: "Please enter a valid email address"
                                        }
                                    })}
                                    type="text"
                                />
                                {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}<br />
                            </Col>

                            <Col>
                                <Form.Control placeholder="Confirm Email"
                                    id="emailConfirm"
                                    {...register("emailConfirm", {
                                        validate: value => { if (value === getValues("email")) return true; else return "Email does not match" },
                                        required: "Confirm Email",
                                        pattern: {
                                            value: /\S+@\S+\.\S+/, // email or admin
                                            message: "Please enter a valid email address"
                                        }
                                    })}
                                    type="text"
                                />
                                {errors.emailConfirm && <Form.Text className="text-danger">{errors.emailConfirm.message}</Form.Text>}<br />
                            </Col>
                        </Row>

                        <Row>
                            <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <Form.Control placeholder="First Name"
                                    id="firstName"
                                    {...register("firstName", {
                                        required: "Frist name is required.",
                                        minLength: {
                                            value: 3,
                                            message: "Frist name is required."
                                        }
                                    })}
                                    type="text"
                                />
                                {errors.firstName && <Form.Text className="text-danger">{errors.firstName.message}</Form.Text>}<br />
                            </Col>

                            <Col>
                                <Form.Control placeholder="Last Name"
                                    id="lastName"
                                    {...register("lastName", {
                                        required: "Last name is required.",
                                        minLength: {
                                            value: 3,
                                            message: "Last name is required."
                                        }
                                    })}
                                    type="text"
                                />
                                {errors.lastName && <Form.Text className="text-danger">{errors.lastName.message}</Form.Text>}<br />
                            </Col>
                        </Row>
                    </Container>

                    <Button variant="primary" type="submit">ACTIVATE</Button>
                    <div>&nbsp;</div>
                </Form>
            </div>
        </div>
    );
}