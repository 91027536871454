import { AuthenticationService } from './authenticationService';

/*
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + String(localStorage.getItem("user"))
*/

export default function AuthHeader() {
    // return authorization header with jwt token
    const currentUser = AuthenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
        return { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${currentUser.token}` };
    } else {
        return {};
    }
}