// TableContainer.js
import React, { Fragment } from 'react';
import { useTable, useSortBy, useFilters, useExpanded, usePagination, useBlockLayout, useResizeColumns } from "react-table"
import { Table, Row, Col, Button } from 'react-bootstrap';
import { Filter, DefaultColumnFilter } from './TableFilters';

const TableContainer = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows, -> we change 'rows' to 'page'
    page,
    prepareRow,
    visibleColumns,
    // below new props related to 'usePagination' hook
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    useResizeColumns
  )

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }
  
  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  return (
    // If you're curious what props we get as a result of calling our getter functions (getTableProps(), getRowProps())
    // Feel free to use console.log()  This will help you better understand how react table works underhood.
      <Fragment>
      { 
      // the actual table code without paging
        <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div {...column.getResizerProps()} className={`resizer ${
                      column.isResizing ? "isResizing" : ""
                    }`}></div>
                    <div {...column.getSortByToggleProps()} >
                    {column.render('Header')}
                    {generateSortingIndicator(column)}
                  </div>
                  <Filter column={column} />
                </th>
              ))}
            </tr>
          ))}
        </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      }

      <Row style={{ maxWidth: 1000, margin: "0 auto", textAlign: "center" }}>
        <Col md={3}>
          <Button
            variant="secondary"
            style={{ margin: "10px" } }
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </Button>
          <Button
            variant="secondary"
            style={{ margin: "10px" } }
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            {"<"}
          </Button>
        </Col>
        <Col md={3} style={{ marginTop: 16 }}>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col md={2}>
          <div
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>
        <Col md={1}>
          <select style={{ margin: "10px -10px", height: "38px", background: "#00000000", border: "0px " }} variant="secondary" as="select" type="select" value={pageSize} onChange={onChangeInSelect}>
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col md={3}>
          <Button variant="secondary" style={{ margin: "10px" }} onClick={nextPage} disabled={!canNextPage}>
            {">"}
          </Button>
          <Button
            variant="secondary"
            style={{ margin: "10px" } }
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}>
            {">>"}
          </Button>
        </Col>
      </Row>
    </Fragment>);
}

export default TableContainer