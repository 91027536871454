import React from 'react';
import { useForm } from "react-hook-form";

import { AuthenticationService } from '../../authenticationService';

import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";

import {
    Form,
    Button
  } from "react-bootstrap";

export default function LoginForm() {
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const navigate = useNavigate();

    const onSubmit = (data) => 
    {
        AuthenticationService.login(data.email, data.password)
        .then(
            user => {
                navigate("/UserArea", { replace: true });
            },
            error => {
                setError("password", { message: error + ". You may need to register first with the email address you have used for the payment." } );
            }
        );
    }
  
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Label htmlFor="email">Email:</Form.Label>
        <Form.Control
          id="email"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /\S+@\S+\.\S+|admin$/, // email or admin
              message: "Please enter a valid email address"
            }
          })}
          type="text"
        />
        {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}<br/>
        <Form.Label  htmlFor="password">Password:</Form.Label ><br/>
        <Form.Control
          id="password"
          {...register("password", {
            required: "required",
            minLength: {
              value: 4,
              message: "Please enter a valid password"
            }
          })}
          type="password"
        />
        {errors.password && <Form.Text className="text-danger">{errors.password.message}</Form.Text>}<br/>
        <Button variant="primary" type="submit">LOGIN</Button><br/>
        <div>&nbsp;</div>
        <Link to="/login/lostpassword/">Lost your password?</Link>
      </Form>
    );
  }